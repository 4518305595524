
import { defineComponent } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { paymentRecordList, getEnterpriseNameList, paymentRecordExport, updateStatus } from '@/api/advance.ts';
import searchComponent from '@/components/search-component/index.vue';
import tableComponent from '@/components/table-component/index.vue';
import dialogDetail from './components/dialog-detail.vue';
import drawerAdvanceDetail from './components/drawer-advance-detail.vue';
import { statusOption, auditStatusOption } from '@/utils/map.ts';

export default defineComponent({
    name: 'Sign',
    data() {
        return {
            searchList: {
                serveCompanyId: {
                    name: '单位名称',
                    prop: 'serveCompanyId',
                    type: 'select',
                    value: '',
                    filterable: true,
                    defaultValue: '',
                    placeholder: '请选择派遣单位',
                    defaultAttrs: {
                        label: 'serveCompanyName',
                        value: 'id'
                    },
                    handleFocus: (item: any) => {
                        if (item.options?.length) return
                        getEnterpriseNameList({
                            loading: true
                        }).then((res: any) => {
                            console.log(111, res);
                            item.options = res.list
                        })
                    }
                },
                userName: {
                    name: '姓名',
                    prop: 'userName',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入姓名'
                },
                phone: {
                    name: '手机',
                    prop: 'phone',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入手机号'
                },
                idCardNo: {
                    name: '身份证号',
                    prop: 'idCardNo',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入身份证号'
                },
                status: {
                    name: '预支状态',
                    prop: 'status',
                    type: 'select',
                    placeholder: '请选择预支状态',
                    value: '',
                    defaultValue: '',
                    options: statusOption,
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                },
                auditStatus: {
                    name: '审批状态',
                    prop: 'auditStatus',
                    type: 'select',
                    placeholder: '请选择审批状态',
                    value: '',
                    defaultValue: '',
                    options: auditStatusOption,
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            tableData: [
            ],
            pageInfo: {
                pageSize: 10,
                pageNum: 1,
                total: 0
            },
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'userName',
                    label: '姓名',
                    width: 80
                },
                {
                    prop: 'serveCompanyName',
                    label: '当前派遣单位',
                    width: 170
                },
                {
                    prop: 'idCardNo',
                    label: '身份证号',
                    width: 170
                },
                {
                    prop: 'phone',
                    label: '手机号',
                    width: 120
                },
                {
                    prop: 'applyRemark',
                    label: '备注',
                    width: 150
                },
                {
                    prop: 'advanceApplyTime',
                    label: '申请时间',
                    sort: 'custom',
                    width: 170
                },
                {
                    prop: 'advanceAmount',
                    label: '预支金额',
                    sort: 'custom',
                    prefix: '¥',
                    color: '#FF7642'
                },
                {
                    prop: 'auditStatus',
                    label: '审批状态',
                    sort: 'custom'
                },
                {
                    prop: 'status',
                    label: '预支状态',
                    sort: 'custom'
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 140,
                    fixed: 'right'
                }
            ],
            showDialog: false,
            showDrawer: false,
            date: '',
            selects: [],
            summaryData: {
                totalCount: 0,
                totalAmount: 0
            }, 
            curItem: {} as any
        }
    },
    components: {
        searchComponent,
        tableComponent,
        dialogDetail,
        drawerAdvanceDetail,
    },
    computed: {
        auditStatusMap() {
            return (val: any) => {
                const cur = auditStatusOption.find((i: any) => i.value === val)
                return cur?.label || ''
            }
        },
        statusMap() {
            return (val: any) => {
                const cur = statusOption.find((i: any) => i.value === val)
                return cur?.label || ''
            }
        },
    },
    beforeMount() {
        if (this.$route?.query?.date) {
            this.date = this.$route?.query?.date as string
        }
        if (this.$route?.query?.auditStatus) {
            this.searchList.auditStatus.value = Number(this.$route?.query?.auditStatus)
        }
        this.getData()
    },
    methods: {
        createQuery(bl = true) {
            const query = {} as any
            if (bl) {
                query.pageNum = this.pageInfo.pageNum
                query.pageSize = this.pageInfo.pageSize
            }
            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`start${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        onExport() {
            const query = this.createQuery(false)
            query.queryDate = this.date
            query.ids = this.selects.join(',')
            paymentRecordExport(query).then((res: any) => {
                window.location.href =
                    process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    res
                this.$message.success('导出成功！')
            })
        },
        handleSearch() {
            this.getData()
        },
        handleExport() {
        },
        getData() {
            const query = this.createQuery()
            query.queryDate = this.date
            paymentRecordList(query).then((res: any) => {
                this.tableData = res.list
                this.pageInfo.total = res.total
                this.summaryData.totalAmount = res.totalAmount
                this.summaryData.totalCount = res.totalCount
            })
        },
        toDetail(row: any) {
            this.curItem = row
            this.showDialog = true
        },
        toDrawer(row: any) {
            this.curItem = row
            this.showDrawer = true
        },
        handleSelectionChange(row: any) {
            console.log(2121, row);
            this.selects = row.map((i: any) => i.id)
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        handleStop(row: any) {
            ElMessageBox.alert('停发后该人员将无法领取薪资，请确认后操作', '提示', {
                type: 'warning',
                confirmButtonText: '确定',
                callback: (action: any) => {
                    console.log(action);
                    updateStatus(row.id).then(res => {
                        ElMessage.success('保存成功')
                        this.getData()
                    })
                },
            })
        }
    }
})
